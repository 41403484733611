import React, { useState } from "react";
import { message, Upload } from 'antd'
import { InboxOutlined } from '@ant-design/icons'

const MultipleUpload = ({
    defaultList = [],
    ossData,
    ...props
}) => {
    const [uploadingFileList, setUploadingFileList] = useState(defaultList)

    const transformFile = (file) => {
        const suffix = file.name.slice(file.name.lastIndexOf("."));
        const filename = Date.now() + suffix;
        file.url = ossData.dir + "/" + filename;

        return file;
    };

    const getExtraData = (file) => {
        return {
            key: file.url,
            OSSAccessKeyId: ossData.accessId,
            policy: ossData.policy,
            Signature: ossData.signature,
        };
    };

    const onChange = async (info) => {
        setUploadingFileList(info.fileList)

        if (props.onChange) {
            props.onChange(info)
        }
    };

    const beforeUpload = async (file) => {
        if (file.size > 20 * 1048576) {
            message.error(`${file.name} is exceed 20 MB limit`);
            return false
        } else {
            return true;
        }
    };

    return (
        <Upload.Dragger
            name="file"
            fileList={uploadingFileList}
            action={ossData.host}
            multiple={false}
            showUploadList={true}
            transformFile={transformFile}
            data={getExtraData}
            beforeUpload={beforeUpload}
            onChange={onChange}
            onPreview={(file) => {
                window.open(file.ossUrl || (ossData.host + '/' + file.url), "_blank")
            }}
        >
            <p className="ant-upload-drag-icon">
                <InboxOutlined />
            </p>
            <p className="ant-upload-text">Click or drag file to this area to upload</p>
            <p className="ant-upload-text">Max 20 MB per file</p>
            <p className="ant-upload-hint">
                Support for a single or bulk upload.
            </p>
        </Upload.Dragger>
    )
}

export default MultipleUpload