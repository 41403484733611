import React, { useState } from 'react'
import {
    Row,
    Input,
    Form,
    InputNumber,
    Typography,
    Divider,
} from 'antd'
import FormItem from '../../components/FormItem'
import { Actions } from '../../pages/leasing-application/[id]'
import {
    renderMobile
} from '../../utils/formatters'

const FormReference = (props) => {
    const [form] = Form.useForm()

    const renderContent = (keyPath, title) => {
        return (
            <>
                <Typography.Title level={4}>{title}</Typography.Title>
                <Divider />

                <Row gutter={24}>
                    <FormItem label="Surname" rules={[{ required: true }]} name={[keyPath, "firstName"]} span={6}>
                        <Input />
                    </FormItem>
                    <FormItem label="Given name" rules={[{ required: true }]} name={[keyPath, "lastName"]} span={6}>
                        <Input style={{ width: '100%' }} />
                    </FormItem>
                    <FormItem label="Relationship to you" rules={[{ required: true }]} name={[keyPath, "relationship"]} span={6}>
                        <Input />
                    </FormItem>
                    <FormItem label="Mobile" rules={[{ required: true }]} name={[keyPath, "mobile"]} span={6}>
                        <InputNumber addonBefore="+61" formatter={(v) => renderMobile(v)} minLength={9} maxLength={9} />
                    </FormItem>
                </Row>
            </>
        )
    }

    return (
        <Form
            form={form}
            layout="vertical"
            size="large"
            className="leasing-step-form"
            colon={false}
            initialValues={props.initialValues}
            onFinish={(values) => {
                console.log('Values', values)
                if (props?.onNext) {
                    props.onNext(props.formName, values)
                } else {
                    props.onSubmit(values)
                }
            }}
        >
            {renderContent(":emergencyContact", "Contact of emergency")}
            {renderContent(":reference1", "Contact of reference 1")}
            {renderContent(":reference2", "Contact of reference 2")}

            <Actions
                onNext={props?.onNext}
                onPrevious={props?.onPrevious}
                onSubmit={props?.onSubmit}
                loading={props?.saving}
            />
        </Form>
    )
}

export default FormReference;
