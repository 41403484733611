export const TITLE = [
    {
        title: 'MR',
        value: 'MR'
    },
    {
        title: 'MRS',
        value: 'MRS'
    },
    {
        title: 'MS',
        value: 'MS'
    },
    {
        title: 'MISS',
        value: 'MISS'
    },
    {
        title: 'DR',
        value: 'DR'
    },
]

export const RENT_PERIODS = [
    {
        title:'per week',
        value:'weekly'
    },
    {
        title:'per fortnight',
        value:'fortnightly'
    },
    {
        title:'per four weeks',
        value:'fourweekly'
    },
    {
        title:'per month',
        value:'monthly'
    },
    {
        title:'per quarter',
        value:'quarterly'
    }
]

export const NATURES = [
    {
        title:'Full time',
        value:'full-time'
    },
    {
        title:'Part time',
        value:'part-time'
    },
    {
        title:'Casual',
        value:'Casual'
    },
]

export const TITLES = [
    {
        title:'MR',
        value:'MR'
    },
    {
        title:'MRS',
        value:'MRS'
    },
    {
        title:'MS',
        value:'MS'
    },
    {
        title:'MISS',
        value:'MISS'
    },
    {
        title:'DR',
        value:'DR'
    }
]

export const ID_TYPES = [
    {
        title:'Driver\'s license',
        value:'driverLicense'
    },
    {
        title:'Passport',
        value:'passport'
    },
    {
        title:'Photo card',
        value:'photoCard'
    },
]

export const INFO_SOURCES = [
    {
        title:'Newspaper',
        value:'Newspaper'
    },
    {
        title:'The Internet',
        value:'The Internet'
    },
    {
        title:'Local paper',
        value:'Local paper'
    },
    {
        title:'Office',
        value:'Office'
    },
    {
        title:'Office window',
        value:'Office window'
    },
    {
        title:'Sign board at property',
        value:'Sign board at property'
    },
    {
        title:'Referral',
        value:'Referral'
    },
]

export const COUNTRIES = [
    {
        title:'Australia',
        value:'AU'
    },
    {
        title:'Overseas',
        value:'ALT'
    },
]

export const AU_STATES = [
    {
        title:'NSW',
        value:'NSW'
    },
    {
        title:'SA',
        value:'SA'
    },
    {
        title:'NT',
        value:'NT'
    },
    {
        title:'ACT',
        value:'ACT'
    },
    {
        title:'QLD',
        value:'QLD'
    },
    {
        title:'TAS',
        value:'TAS'
    },
    {
        title:'WA',
        value:'WA'
    },
    {
        title:'VIC',
        value:'VIC'
    },
]

export const EMPLOYMENT_TYPE = [
    {
        title:'Part time',
        value:'part-time'
    },{
        title:'Full time',
        value:'full-time'
    },{
        title:'Casual',
        value:'casual'
    },
]