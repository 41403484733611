import React, { useState } from 'react'
import {
    Row,
    Button,
    Typography,
    Divider,
    Descriptions,
} from 'antd'
import { Actions } from '../../pages/leasing-application/[id]'
import { renderCamelCase, renderNumber, renderMobile, renderAddress, renderLivingTime } from '../../utils/formatters'
import { isValidHttpUrl } from '../../utils/typeCheck'

const keyToLabel = (str) => typeof str === "string" ? renderCamelCase(str.replaceAll(":", "")) : ""

const FormConfirmation = (props) => {
    const { values } = props;

    const renderDescription = (data) => {
        if (!Array.isArray(data) || data.length < 1) return null;
        return (
            <Descriptions colon={false} column={2} labelStyle={{ color: 'grey' }}>
                {
                    data.map(({ label, value = '-', span = 1 }, index) => (
                        <Descriptions.Item key={index} label={label} span={span}>
                            {isValidHttpUrl(value) ? <a href={value} target="_blank">View</a> : value}
                        </Descriptions.Item>
                    ))
                }
            </Descriptions>
        )
    }

    const renderContent = (data, type) => {
        let mapped = []
        switch (type) {
            case "leasingDetails":
                mapped = [
                    { label: 'Weekly rent', value: '$' + renderNumber(data["rent"]) },
                    { label: 'Bond', value: '$' + renderNumber(data["bond"]) },
                    { label: 'Commence Date', value: data["leaseCommenceDate"].format('DD/MM/YYYY') },
                    { label: 'Lease Term', value: `${data["leaseTerm"]["years"]}  year(s), ${data["leaseTerm"]["months"]} month(s)` },
                    { label: 'Number of Adult', value: data["numAdult"] },
                    { label: 'Number of Children', value: data["numChildren"] },
                    ...Object.keys(data).filter(item => item.startsWith("childAge-")).map((key, idx) => ({ label: `Child ${idx + 1} Age`, value: data[key] }))
                ]
                break;
            case "applicantDetails":
                mapped = [
                    { label: 'Title', value: data["title"] },
                    { label: 'First Name', value: data["firstName"] },
                    { label: 'Last Name', value: data["lastName"] },
                    { label: 'Mobile', value: '0' + renderMobile(data["mobile"]) },
                    { label: 'Phone', value: '0' + renderMobile(data["phone"]) },
                    { label: 'Info Source', value: data["infoSource"] },
                    { label: 'ID Type', value: data["idType"] },
                    ...(
                        data["idType"] === 'driverLicense' ?
                            [
                                { label: 'Driver License No', value: data["driverLicenseNo"] },
                                { label: 'Driver License State', value: data["driverLicenseState"] },
                                { label: 'Driver License Expiry', value: data["driverLicenseExpiry"].format("DD/MM/YYYY") },
                            ]
                            :
                            data["idType"] === "passport"
                                ?
                                [
                                    { label: 'Passport No', value: data["passportNo"] },
                                    { label: 'Passport Country', value: data["passportCountry"] }
                                ]
                                : // photoCard
                                [
                                    { label: 'Photo card', value: data["photoCardNo"] }
                                ]
                    ),
                ]
                break;
            case "applicantAddress":
                mapped = [
                    { label: 'Current Addres', value: renderAddress(data[":currentAddress"][":address"]) },
                    { label: 'Name of landlord', value: data[":currentAddress"]["landlordName"] },
                    { label: 'Phone', value: '0' + renderMobile(data[":currentAddress"]["phone"]) },
                    { label: 'Weekly paid', value: '$' + renderNumber(data[":currentAddress"]["weeklyRentPaid"]) },
                    { span: 2, label: 'Lived for', value: renderLivingTime(data[":currentAddress"]["livingTime"]) },
                    { span: 2, label: 'Reason for leaving', value: data[":currentAddress"]["reasonForLeaving"] },
                    ...(
                        data[":previousAddress"]
                            ?
                            [
                                { label: 'Previous Addres', value: renderAddress(data?.[":previousAddress"]?.[":address"]) },
                                { label: 'Name of landlord', value: data[":previousAddress"]["landlordName"] },
                                { label: 'Phone', value: '0' + renderMobile(data[":previousAddress"]["phone"]) },
                                { label: 'Weekly paid', value: '$' + renderNumber(data[":previousAddress"]["weeklyRentPaid"]) },
                                { label: 'Lived for', value: renderLivingTime(data[":previousAddress"]["livingTime"]) },
                                { label: 'Bond fully refunded', value: data[":previousAddress"]["bondFullyRefunded"] ? "Yes" : "No" },
                                { span: 2, label: 'Reason not fully refunded', value: data[":previousAddress"]["reasonNotFullyRefunded"] },
                            ]
                            :
                            []
                    )
                ]
                break;
            case "employment":
                mapped = [
                    { label: 'Current Employer Name', value: data[":currentEmployment"]["employerName"] },
                    { label: 'Contact Name', value: data[":currentEmployment"]["contactName"] },
                    { label: 'Net income (per annual)', value: '$' + renderNumber(data[":currentEmployment"]["netIncome"]) },
                    { label: 'Nature', value: data[":currentEmployment"]["nature"] },
                    { label: 'Duration', value: renderLivingTime(data[":currentEmployment"]["duration"]) },
                    { label: 'Occupation', value: data[":currentEmployment"]["occupation"] },
                    { span: 2, label: 'Phone', value: '0' + renderMobile(data[":currentEmployment"]["phone"]) },
                    { span: 2, label: 'Address', value: renderAddress(data[":currentEmployment"][":address"]) },
                    ...(
                        data[":previousEmployment"]
                            ?
                            [
                                { label: 'Previous Employer Name', value: data[":previousEmployment"]["employerName"] },
                                { label: 'Contact Name', value: data[":previousEmployment"]["contactName"] },
                                { label: 'Net income (per annual)', value: '$' + renderNumber(data[":previousEmployment"]["netIncome"]) },
                                { label: 'Nature', value: data[":previousEmployment"]["nature"] },
                                { label: 'Duration', value: renderLivingTime(data[":previousEmployment"]["duration"]) },
                                { label: 'Occupation', value: data[":previousEmployment"]["occupation"] },
                                { span: 2, label: 'Phone', value: '0' + renderMobile(data[":previousEmployment"]["phone"]) },
                                { span: 2, label: 'Address', value: renderAddress(data[":previousEmployment"][":address"]) },
                            ]
                            : []
                    )
                ]
                break;
            case "references":
                mapped = [
                    { label: 'Emergency Contact', value: data[":emergencyContact"]["firstName"] + " " + data[":emergencyContact"]["lastName"] },
                    { label: 'Mobile', value: '0' + renderMobile(data[":emergencyContact"]["mobile"]) },
                    { span: 2, label: 'Relationship', value: data[":emergencyContact"]["relationship"] },

                    { label: 'Reference 1', value: data[":reference1"]["firstName"] + " " + data[":reference1"]["lastName"] },
                    { label: 'Mobile', value: '0' + renderMobile(data[":reference1"]["mobile"]) },
                    { span: 2, label: 'Relationship', value: data[":reference1"]["relationship"] },

                    { label: 'Reference 2', value: data[":reference2"]["firstName"] + " " + data[":reference2"]["lastName"] },
                    { label: 'Mobile', value: '0' + renderMobile(data[":reference2"]["mobile"]) },
                    { span: 2, label: 'Relationship', value: data[":reference2"]["relationship"] },
                ]
                break;
            case "otherInformation":
                console.log(data)
                mapped = [
                    { span: 2, label: 'Number of cars', value: data["numCars"] },
                    ...(new Array(data["numCars"])).fill(0).map((car, index) => (
                        { span: 2, label: `Car ${index + 1}`, value: data[`car-${index}`] }
                    )),

                    { span: 2, label: 'Number of pets', value: data["numPet"] },
                ];
                for (let i = 0; i < data["numPet"]; i++) {
                    mapped.push({ label: `Breed ${i + 1}`, value: data[`breedType-${i}`] });
                    mapped.push({ label: `Council Registration ${i + 1}`, value: data[`councilRegistration-${i}`] });
                    mapped.push({ label: `Weight ${i + 1}`, value: data[`weight-${i}`] });
                    mapped.push({ label: `Photo ${i + 1}`, value: data[`photo-${i}`] });
                }
                mapped.push({ span: 2, label: "Supporting Documents", value: "" })
                for (let i = 0; i < data[":documents"]?.length; i++) {
                    const url = data[":documents"][i]?.ossUrl
                    if (url) {
                        mapped.push({
                            span: 2,
                            label: `Doc ${i + 1}`,
                            value: url
                        })
                    }
                }
                break;
        }

        return renderDescription(mapped);
    }

    return (
        <>
            {
                Object.keys(values).map((key, index) => {
                    const section = values[key]
                    return (
                        <React.Fragment key={key}>
                            <Row justify="space-between" align="bottom">
                                <Typography.Title level={4} style={{ marginTop: 24, marginBottom: 0 }}>{keyToLabel(key)}</Typography.Title>
                                <Button onClick={() => {
                                    console.log(keyToLabel(key))
                                    props.onChangeStep(keyToLabel(key))
                                }}>Edit</Button>
                            </Row>
                            <Divider style={{ marginTop: 12, marginBottom: 12 }} />
                            {renderContent(section, key)}
                        </React.Fragment>
                    )
                })
            }
            <Actions
                onNext={props?.onNext}
                onPrevious={props?.onPrevious}
                onSubmit={props?.onSubmit}
                loading={props?.saving}
            />
        </>
    )
}

export default FormConfirmation;
