import React, { useEffect, useState } from 'react'
import { navigate } from "gatsby"
import {
    Row,
    Col,
    Card,
    Button,
    Steps,
    Typography,
    Space,
    message,
    Modal,
} from 'antd'
import { compose } from 'recompose'
import { inject, observer } from 'mobx-react'
import { EnvironmentOutlined } from '@ant-design/icons'
import moment from 'moment'
import Sticky from 'react-sticky-el'
import { get, defaultTo, clone } from 'lodash'

import LightContainer from "../../components/LightContainer"
import withRoot from '../../withRoot'

import './index.less'
import FormApplication from '../../components/leasing/FormApplicant'
import FormLeasing from '../../components/leasing/FormLeasing'
import FormEmployment from '../../components/leasing/FormEmployment'
import FormReference from '../../components/leasing/FormReference'
import FormAddress from '../../components/leasing/FormAddress'
import FormOther from '../../components/leasing/FormOther'
import FormConfirmation from '../../components/leasing/FormConfirmation'

const { Step } = Steps;

const steps = [
    'Leasing Details',
    'Applicant Details',
    'Applicant Address',
    'Employment',
    'References',
    'Other Information',
    'Confirmation'
]

export const Actions = (props) => {
    const {
        loading,
        onPrevious,
        onNext,
        onSubmit,
    } = props

    return (
        <div className='step-form-action-container' >
            <Space size={12}>
                {onPrevious && <Button loading={loading} key="previous" size="large" onClick={onPrevious}>Previous</Button>}
                {onNext && <Button loading={loading} key="next" size="large" type="primary" htmlType="submit">Next</Button>}
                {onSubmit && <Button loading={loading} key="submit" size="large" type="primary" htmlType="submit" onClick={onSubmit}>Submit</Button>}
            </Space>
        </div >
    )
}

const LeasingApplicaiton = ({
    location,
    id: leasingId,
    params,
    appStore
}) => {
    const paramString = location.search.slice(1);
    const searchParams = new URLSearchParams(paramString);

    const address = searchParams.get("address");
    const agencyId = searchParams.get("agencyId");
    const edit = searchParams.get("edit");

    const [step, setStep] = useState(0)
    const [values, setValues] = useState({})
    const [ossData, setOssData] = useState({})
    const [saving, setSaving] = useState(false)
    const [loadingDraft, setLoadingDraft] = useState(!!edit)

    const loadData = (leasingDetails) => {
        // console.log('leasingDetails', leasingDetails)
        // leasingDetails
        const childrenAge = {}
        const ages = JSON.parse(leasingDetails["ageOfChildren"])
        if (Array.isArray(ages)) {
            ages.forEach((ele, idx) => {
                childrenAge[`childAge-${idx}`] = ele;
            })
        }

        let applicant = get(leasingDetails, ":applicant", {})

        // applicantAddress 
        const currAddress = applicant?.[":currentAddress"];
        const prevAddress = applicant?.[":previousAddress"]
        if (currAddress) {
            currAddress["livingTime"] = JSON.parse(currAddress["livingTime"])
            currAddress["phone"] = defaultTo(currAddress["phone"], "").substring(3)
        }
        if (prevAddress) {
            prevAddress["livingTime"] = JSON.parse(prevAddress["livingTime"])
            prevAddress["phone"] = defaultTo(prevAddress["phone"], "").substring(3)
        }

        // employment
        const currEmployment = applicant?.[":currentEmployment"]
        const prevEmployment = applicant?.[":previousEmployment"]
        if (currEmployment) {
            currEmployment["duration"] = JSON.parse(currEmployment["duration"])
            currEmployment["phone"] = defaultTo(currEmployment["phone"], "").substring(3)
        }
        if (prevEmployment) {
            prevEmployment["duration"] = JSON.parse(prevEmployment["duration"])
            prevEmployment["phone"] = defaultTo(prevEmployment["phone"], "").substring(3)
        }

        // references 
        const emContact = applicant?.[":emergencyContact"]
        const refContact = applicant?.[":reference1"]
        const refContact2 = applicant?.[":reference2"]
        if (emContact) {
            emContact["mobile"] = emContact["mobile"].substring(3)
        }
        if (refContact) {
            refContact["mobile"] = refContact["mobile"].substring(3)
        }
        if (refContact2) {
            refContact2["mobile"] = refContact2["mobile"].substring(3)
        }

        // other info
        let otherInformation = {}
        if (get(applicant, ":cars.length", 0) > 0) {
            otherInformation["numCars"] = applicant[":cars"].length
            applicant[":cars"].map((car, index) => otherInformation[`car-${index}`] = car?.[":vehicle"]?.rego)
        }

        if (get(applicant, ":pets.length", 0) > 0) {
            otherInformation["numPet"] = applicant[":pets"].length
            applicant[":pets"].forEach((pet, index) => {
                otherInformation[`breedType-${index}`] = pet[":pet"].breedType
                otherInformation[`councilRegistration-${index}`] = pet[":pet"].councilRegistration
                otherInformation[`weight-${index}`] = pet[":pet"].weight
                otherInformation[`photo-${index}`] = pet[":pet"].photo
            })
        } else {
            otherInformation["numPet"] = 0
        }

        if (get(leasingDetails, ":documents.length", 0) > 0) {
            otherInformation[":documents"] = leasingDetails[":documents"].map(({ fileName, url, ...rest }) => ({
                ...rest,
                name: fileName,
                ossUrl: url
            }))
            otherInformation["signature"] = applicant["signature"]
            otherInformation["signedDate"] = applicant["signedDate"]
        }
        // console.log('leasingDetails', leasingDetails);
        return ({
            leasingDetails: {
                rent: leasingDetails["rent"],
                bond: leasingDetails["bond"],
                leaseCommenceDate: leasingDetails["leaseCommenceDate"] ? moment(leasingDetails["leaseCommenceDate"]) : moment(),
                leaseTerm: leasingDetails["leaseTerm"] ? JSON.parse(leasingDetails["leaseTerm"]) : undefined,
                numAdult: leasingDetails["numAdult"],
                numChildren: leasingDetails["numChildren"],
                ...childrenAge
            },
            applicantDetails: {
                title: applicant["title"],
                firstName: applicant["firstName"],
                lastName: applicant["lastName"],
                dob: moment(applicant["dob"]),
                mobile: defaultTo(get(applicant, "mobile", ""), "").substring(3),
                phone: defaultTo(get(applicant, "phone", ""), "").substring(3),
                email: applicant["email"],
                infoSource: applicant["infoSource"],
                idType: applicant["idType"],
                driverLicenseExpiry: moment(applicant["driverLicenseExpiry"]),
                driverLicenseNo: applicant["driverLicenseNo"],
                driverLicenseState: applicant["driverLicenseState"],
                passportCountry: applicant["passportCountry"],
                passportNo: applicant["passportNo"],
                photoCardNo: applicant["photoCardNo"],
            },
            applicantAddress: {
                [":currentAddress"]: currAddress,
                [":previousAddress"]: prevAddress
            },
            employment: {
                [":currentEmployment"]: currEmployment,
                [":previousEmployment"]: prevEmployment
            },
            references: {
                [":emergencyContact"]: emContact,
                [":reference1"]: refContact,
                [":reference2"]: refContact2
            },
            otherInformation
        })
    }

    const loadDataForCreate = (leasingDetails) => {
        let listing = get(leasingDetails, ":listing", {})
        let defaultRentPrice = null;
        const price = listing?.[':prices']?.['LIST']?.amount || null
        const showTextForPrice = listing?.showTextForPrice
        if(!showTextForPrice&&price) defaultRentPrice = price;
        return ({
            leasingDetails: {
                rent: Number(defaultRentPrice)
            }
        })
    }

    const init = async () => {
        try {

            const sign = await appStore.clientApi.getOssSignature_('leasing', leasingId)

            const leasingDetails = await appStore.clientApi.getLeasingDetail_(leasingId)

            if (!leasingDetails) {
                throw new Error("Failed to get leasing details")
            }

            if (edit) {
                // load previous saved draft
                setValues(loadData(leasingDetails))
                setLoadingDraft(false)
            }else {
                setValues(loadDataForCreate(leasingDetails))
            }
            const termsCondition = await appStore.clientApi.getAgencyTermsAndConditions_(agencyId)
            Modal.info({
                title: 'Lease terms & conditions',
                content: (
                    <div style={{ maxHeight: 320, overflow: 'auto' }}>
                        <div dangerouslySetInnerHTML={{ __html: termsCondition.leaseTermsCondition }} />
                    </div>
                ),
                okText: "Agree"
            })

            const dir = `agency/${agencyId}/leasing/${leasingId}`
            setOssData({
                dir,
                ...sign
            })
        } catch (err) {
            console.error(err)
            navigate('/property404')
        }
    }

    useEffect(() => {
        init()
    }, [])

    const buildAction = () => {
        if (step === 0) {
            return { saving, onNext }
        } else if (step === steps.length - 1) {
            return { saving, onSubmit }
        } else {
            return { saving, onPrevious, onNext }
        }
    }

    const onChangeStep = (formName) => {
        const index = steps.indexOf(formName)
        // console.log(index)
        if (index >= 0) {
            setStep(index)
        }
    }

    const buildContent = () => {
        switch (step) {
            case 0:
                return <FormLeasing formName="leasingDetails" initialValues={values["leasingDetails"]} {...buildAction()} />
            case 1:
                return <FormApplication formName="applicantDetails" initialValues={values["applicantDetails"]} {...buildAction()} />
            case 2:
                return <FormAddress formName="applicantAddress" initialValues={values["applicantAddress"]} {...buildAction()} />
            case 3:
                return <FormEmployment formName="employment" initialValues={values["employment"]} {...buildAction()} />
            case 4:
                return <FormReference formName="references" initialValues={values["references"]} {...buildAction()} />
            case 5:
                return <FormOther ossData={ossData} formName="otherInformation" initialValues={values["otherInformation"]} leasingId={leasingId} {...buildAction()} />
            case 6:
                return <FormConfirmation values={values} onChangeStep={onChangeStep} {...buildAction()} />
            default:
                return null;
        }
    }

    const onNext = async (formName, paramValues) => {
        if (paramValues) {
            setSaving(true)
            let res = null;
            const data = clone(paramValues)
            try {
                switch (formName) {
                    case "leasingDetails":
                        data["leaseCommenceDate"] = data["leaseCommenceDate"].format("YYYY-MM-DD")
                        data["leaseTerm"]["years"] = parseInt(data["leaseTerm"]["years"])
                        data["leaseTerm"]["months"] = parseInt(data["leaseTerm"]["months"])
                        data["rentDuration"] = "weekly" // hidden field but required
                        const ageOfChildren = []
                        Object.keys(paramValues).forEach(key => {
                            if (key.startsWith("childAge")) {
                                ageOfChildren.push(parseInt(data[key]))
                                delete data[key]
                            }
                        })
                        data["ageOfChildren"] = ageOfChildren

                        res = await appStore.clientApi.fillLeasingApplication_(leasingId, data)
                        break;
                    case "applicantDetails":
                        data["dob"] = data["dob"].format("YYYY-MM-DD")
                        res = await appStore.clientApi.updateLeasingApplication_(leasingId, data)
                        break;
                    case "applicantAddress":
                        if (data[":currentAddress"]?.["livingTime"]) {
                            data[":currentAddress"]["livingTime"]["years"] = parseInt(data[":currentAddress"]["livingTime"]["years"])
                            data[":currentAddress"]["livingTime"]["months"] = parseInt(data[":currentAddress"]["livingTime"]["months"])
                        }
                        if (data?.[":previousAddress"]?.["livingTime"]) {
                            data[":previousAddress"]["livingTime"]["years"] = parseInt(data[":previousAddress"]["livingTime"]["years"])
                            data[":previousAddress"]["livingTime"]["months"] = parseInt(data[":previousAddress"]["livingTime"]["months"])
                        }
                        res = await appStore.clientApi.updateLeasingApplication_(leasingId, data)
                        break;
                    case "employment":
                        if (data[":currentEmployment"]?.["duration"]) {
                            data[":currentEmployment"]["duration"]["years"] = parseInt(data[":currentEmployment"]["duration"]["years"])
                            data[":currentEmployment"]["duration"]["months"] = parseInt(data[":currentEmployment"]["duration"]["months"])
                        }
                        if (data?.[":previousEmployment"]?.["duration"]) {
                            data[":previousEmployment"]["duration"]["years"] = parseInt(data[":previousEmployment"]["duration"]["years"])
                            data[":previousEmployment"]["duration"]["months"] = parseInt(data[":previousEmployment"]["duration"]["months"])
                        }
                        res = await appStore.clientApi.updateLeasingApplication_(leasingId, data)
                        break;
                    case "references":
                        res = await appStore.clientApi.updateLeasingApplication_(leasingId, data)
                        break;
                    case "otherInformation":
                        const cars = []
                        const pets = []
                        for (let i = 0; i < data["numCars"]; i++) {
                            cars.push({ rego: data[`car-${i}`] })
                        }

                        for (let i = 0; i < data["numPet"]; i++) {
                            pets.push({
                                breedType: data[`breedType-${i}`],
                                councilRegistration: data[`councilRegistration-${i}`],
                                weight: data[`weight-${i}`],
                                photo: data[`photo-${i}`],
                            })
                        }
                        if (!data["signature"] || !data["signedDate"]) {
                            throw new Error("Missing signature")
                        }

                        res = await appStore.clientApi.updateLeasingApplication_(leasingId, {
                            [":cars"]: cars,
                            [":pets"]: pets,
                            ["numPet"]: data["numPet"],
                            ["signature"]: data["signature"],
                            ["signedDate"]: data["signedDate"]
                        })

                        break;
                    default:
                        message.error('ERROR unexpected case')
                        break;
                }
                setSaving(false)
                setValues(s => ({ ...s, [formName]: paramValues }))
                setStep(s => {
                    if (s < steps.length - 1) {
                        return s + 1
                    }
                    return s
                })
                window.scrollTo({ top: 0, left: 100 })
            } catch (err) {
                setSaving(false);
                console.error(err)
                message.error(typeof err === "string" ? err : "Failed to start leasing applicaiton")
            }
        }
    }

    const onPrevious = () => {
        setStep(s => s - 1)
        window.scrollTo({ top: 0, left: 100 })
    }

    const onSubmit = async () => {
        try {
            setSaving(true);
            const res = await appStore.clientApi.submitLeasingApplication_(leasingId)
            setSaving(false);
            navigate(`/success-application?leasingId=${leasingId}`)
        } catch (err) {
            setSaving(false);
            message.error("Failed to submit the form, please contact for support")
            console.error(err)
        }
    }

    const loadingIndicator = <Typography.Text>Loading previous data...</Typography.Text>

    return (
        <LightContainer>
            {
                loadingDraft
                    ? loadingIndicator
                    :
                    <>
                        <Typography.Title level={2}>Leasing Application</Typography.Title>

                        {
                            address &&
                            <Typography.Text><EnvironmentOutlined /> {address}</Typography.Text>
                        }

                        <Row gutter={24} className="step-form">
                            <Col span={6}>
                                <Sticky stickyStyle={{ top: 20 }}>
                                    <Card>
                                        <Steps
                                            size="small"
                                            direction="vertical"
                                            current={step}
                                        // onChange={(value) => setStep(value)}
                                        >
                                            {steps.map(ele => <Step key={ele} title={ele} />)}
                                        </Steps>
                                    </Card>
                                </Sticky>
                            </Col>

                            <Col span={18}>
                                <Card title={steps[step]}>
                                    {buildContent()}
                                </Card>
                            </Col>
                        </Row>
                    </>
            }
        </LightContainer>
    )
}

export default compose(withRoot, inject('appStore', 'uiStore'), observer)(LeasingApplicaiton)