import React, { useState } from 'react'
import {
    Row,
    Checkbox,
    Form,
    Input,
    InputNumber,
    Typography,
    Select,
    Divider,
} from 'antd'
import FormItem from '../../components/FormItem'
import { Actions } from '../../pages/leasing-application/[id]'
import {
    AU_STATES,
    EMPLOYMENT_TYPE,
} from '../../utils/leasingUtils'
import {
    renderMobile, renderNumber
} from '../../utils/formatters'
import _ from 'lodash'

const FormEmployment = (props) => {
    const [form] = Form.useForm()

    const [checked, setChecked] = useState(!!props.initialValues?.[":previousEmployment"])

    const content = (previous = false) => {
        const path = previous ? ":previousEmployment" : ":currentEmployment"
        return (
            <>
                {
                    previous &&
                    <>
                        <Typography.Title level={4} style={{ marginTop: 24 }}>{previous ? "Previous employment" : "Current employment"}</Typography.Title>
                        <Divider />
                    </>
                }
                <Row gutter={24}>
                    <FormItem label="Occupation" rules={[{ required: true }]} name={[path, "occupation"]} span={7}>
                        <Input />
                    </FormItem>
                    <FormItem label="Nature of employment" rules={[{ required: true }]} name={[path, "nature"]} span={7}>
                        <Select>
                            {
                                EMPLOYMENT_TYPE.map(item => (
                                    <Select.Option key={item.value} value={item.value}>{item.title}</Select.Option>
                                ))
                            }
                        </Select>
                    </FormItem>
                    <FormItem label="Lenth of employment" rules={[{ required: true }]} name={[path, "duration", "years"]} span={5}>
                        <InputNumber addonAfter="Years" />
                    </FormItem>
                    <FormItem label="Months" rules={[{ required: true }]} name={[path, "duration", "months"]} span={5}>
                        <InputNumber addonAfter="Months" min={0} max={11} />
                    </FormItem>
                    <FormItem label="Employment's name" rules={[{ required: true }]} name={[path, "employerName"]}>
                        <Input />
                    </FormItem>
                    <FormItem label="Net income (per annual)" rules={[{ required: true }]} name={[path, "netIncome"]}>
                        <InputNumber addonBefore="$" formatter={renderNumber} />
                    </FormItem>
                    <FormItem label="Contact name" rules={[{ required: true }]} name={[path, "contactName"]}>
                        <Input />
                    </FormItem>
                    <FormItem label="Phone" rules={[{ required: true }]} name={[path, "phone"]}>
                        <InputNumber addonBefore="+61" formatter={(v) => renderMobile(v)} minLength={9} maxLength={9} />
                    </FormItem>
                </Row>

                <Row gutter={24}>
                    <FormItem label="Address" rules={[{ required: true }]} name={[path, ":address", "line1"]} span={24}>
                        <Input />
                    </FormItem>
                    <FormItem label="Suburb" rules={[{ required: true }]} name={[path, ":address", "city"]} span={8}>
                        <Input />
                    </FormItem>
                    <FormItem label="State" rules={[{ required: true }]} name={[path, ":address", "state"]} span={8}>
                        <Select>
                            {
                                AU_STATES.map(item => (
                                    <Select.Option key={item.value} value={item.value}>{item.title}</Select.Option>
                                ))
                            }
                        </Select>
                    </FormItem>
                    <FormItem label="Postcode" rules={[{ required: true }]} name={[path, ":address", "postcode"]} span={8}>
                        <InputNumber maxLength={6} />
                    </FormItem>
                </Row>
            </>
        )
    }

    return (
        <Form
            form={form}
            layout="vertical"
            size="large"
            className="leasing-step-form"
            colon={false}
            initialValues={props.initialValues}
            onFinish={(values) => {
                console.log('Values', values)

                if (_.get(values, ":currentEmployment")) {
                    values[":currentEmployment"][":address"]["country"] = "AU"
                }

                if (_.get(values, ":previousEmployment")) {
                    values[":previousEmployment"][":address"]["country"] = "AU"
                }

                if (props?.onNext) {
                    props.onNext(props.formName, values)
                } else {
                    props.onSubmit(values)
                }
            }}
        >
            {/* current */}
            {content(false)}

            <Checkbox defaultChecked={checked} onChange={() => setChecked(s => !s)}>Add Previous Employment</Checkbox>

            {checked && content(true)}

            <Actions
                onNext={props?.onNext}
                onPrevious={props?.onPrevious}
                onSubmit={props?.onSubmit}
                loading={props?.saving}
            />
        </Form>
    )
}

export default FormEmployment;
