import React, { useState } from 'react'
import {
    Row,
    Card,
    DatePicker,
    Form,
    Input,
    InputNumber,
    Select,
} from 'antd'
import FormItem from '../../components/FormItem'
import {
    renderMobile
} from '../../utils/formatters'
import {
    INFO_SOURCES,
    ID_TYPES,
    AU_STATES,
    COUNTRIES,
    TITLE,
} from '../../utils/leasingUtils'
import { Actions } from '../../pages/leasing-application/[id]'

const FormApplication = (props) => {
    const [form] = Form.useForm()

    return (
        <Form
            form={form}
            layout="vertical"
            size="large"
            className="leasing-step-form"
            colon={false}
            initialValues={props.initialValues}
            onFinish={(values) => {
                if (props?.onNext) {
                    props.onNext(props.formName, values)
                } else {
                    props.onSubmit(values)
                }
            }}
        >
            <Row gutter={24}>
                <FormItem label="Title" rules={[{ required: true }]} name="title" span={8}>
                    <Select>
                        {
                            TITLE.map(item => (
                                <Select.Option key={item.value} value={item.value}>{item.title}</Select.Option>
                            ))
                        }
                    </Select>
                </FormItem>
                <FormItem label="First Name" rules={[{ required: true }]} name="firstName" span={8}>
                    <Input />
                </FormItem>
                <FormItem label="Last Name" rules={[{ required: true }]} name="lastName" span={8}>
                    <Input />
                </FormItem>
                <FormItem label="Date of birth" rules={[{ required: true }]} name="dob" span={8}>
                    <DatePicker style={{ width: '100%' }} format="DD/MM/YYYY" disabledDate={(current) => current.isAfter(new Date(), 'day')} />
                </FormItem>
                <FormItem label="Mobile no." rules={[{ required: true }]} name="mobile" span={8}>
                    <InputNumber addonBefore="+61" formatter={(v) => renderMobile(v)} minLength={9} maxLength={9} />
                </FormItem>
                <FormItem label="Work phone no." rules={[{ required: true }]} name="phone" span={8}>
                    <InputNumber addonBefore="+61" formatter={(v) => renderMobile(v)} minLength={9} maxLength={9} />
                </FormItem>
                <FormItem label="Email" rules={[{ required: true }]} name="email">
                    <Input type="email" />
                </FormItem>
                <FormItem label="How find this property" rules={[{ required: true }]} name="infoSource">
                    <Select>
                        {
                            INFO_SOURCES.map(item => (
                                <Select.Option key={item.value} value={item.value}>{item.title}</Select.Option>
                            ))
                        }
                    </Select>
                </FormItem>
                <FormItem label="ID type" rules={[{ required: true }]} name="idType">
                    <Select>
                        {
                            ID_TYPES.map(item => (
                                <Select.Option key={item.value} value={item.value}>{item.title}</Select.Option>
                            ))
                        }
                    </Select>
                </FormItem>
            </Row>

            <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) => prevValues.idType !== currentValues.idType}
            >
                {({ getFieldValue }) => {
                    switch (getFieldValue("idType")) {
                        case 'driverLicense':
                            return (
                                <Card className="subform">
                                    <Form.Item label="Driver's license no." rules={[{ required: true }]} name="driverLicenseNo">
                                        <Input maxLength={24} />
                                    </Form.Item>
                                    <Form.Item label="Driver's license expire day" rules={[{ required: true }]} name="driverLicenseExpiry">
                                        <DatePicker style={{ width: '100%' }} format="DD/MM/YYYY" />
                                    </Form.Item>
                                    <Form.Item label="Driver's license state" rules={[{ required: true }]} name="driverLicenseState">
                                        <Select>
                                            {
                                                AU_STATES.map(item => (
                                                    <Select.Option key={item.value} value={item.value}>{item.title}</Select.Option>
                                                ))
                                            }
                                        </Select>
                                    </Form.Item>
                                </Card>
                            )
                        case 'passport':
                            return (
                                <Card className="subform">
                                    <Form.Item label="Passport no." rules={[{ required: true }]} name="passportNo">
                                        <Input />
                                    </Form.Item>
                                    <Form.Item label="Passport country" rules={[{ required: true }]} name="passportCountry">
                                        <Input />
                                    </Form.Item>
                                </Card>
                            )
                        case 'photoCard':
                            return (
                                <Card className="subform">
                                    <Form.Item label="Photo no." rules={[{ required: true }]} name="photoCardNo">
                                        <InputNumber style={{ width: 240 }} />
                                    </Form.Item>
                                </Card>
                            )
                        default:
                            return null;
                    }
                }}
            </Form.Item>

            <Actions
                onNext={props?.onNext}
                onPrevious={props?.onPrevious}
                onSubmit={props?.onSubmit}
                loading={props?.saving}
            />
        </Form>
    )
}

export default FormApplication;
