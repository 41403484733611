import React from 'react'
import {
    Col,
    Form
} from 'antd'

const FormItem = (props) => {
    const { span = 12, children, ...rest } = props
    return (
        <Col span={span}>
            <Form.Item {...rest}>
                {children}
            </Form.Item>
        </Col>
    )
}

export default FormItem