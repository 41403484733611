import React, { useState } from 'react'
import {
    Row,
    Form,
    Input,
    InputNumber,
    Typography,
    Select,
    Divider,
    Radio,
    Checkbox,
} from 'antd'
import FormItem from '../FormItem'
import {
    renderMobile,
    renderNumber
} from '../../utils/formatters'
import {
    AU_STATES,
    COUNTRIES,
} from '../../utils/leasingUtils'
import { Actions } from '../../pages/leasing-application/[id]'
import _ from 'lodash'

const FormAddress = (props) => {
    const [form] = Form.useForm()

    const [checked, setChecked] = useState(_.get(props.initialValues, ":previousAddress", false))

    const renderAddress = (previous = false) => {
        const keyPath = previous ? ":previousAddress" : ":currentAddress"

        return (
            <>
                <Row gutter={24}>
                    <FormItem label="Country" rules={[{ required: true }]} name={[keyPath, ":address", "country"]} span={8}>
                        <Select>
                            {
                                COUNTRIES.map(item => (
                                    <Select.Option key={item.value} value={item.value}>{item.title}</Select.Option>
                                ))
                            }
                        </Select>
                    </FormItem>
                </Row>

                <Row gutter={24}>
                    <FormItem label="Address" rules={[{ required: true }]} name={[keyPath, ":address", "line1"]} span={24}>
                        <Input />
                    </FormItem>
                    <FormItem label="Suburb" rules={[{ required: true }]} name={[keyPath, ":address", "city"]} span={8}>
                        <Input />
                    </FormItem>
                    <Form.Item
                        noStyle
                        shouldUpdate={(prevValues, currentValues) => prevValues?.[keyPath]?.[":address"]?.["country"] !== currentValues?.[keyPath]?.[":address"]?.["country"]}
                    >
                        {({ getFieldValue }) => (
                            getFieldValue(keyPath)?.[":address"]?.["country"] === 'AU' ?
                                <FormItem label="State" rules={[{ required: true }]} name={[keyPath, ":address", "state"]} span={8}>
                                    <Select>
                                        {
                                            AU_STATES.map(item => (
                                                <Select.Option key={item.value} value={item.value}>{item.title}</Select.Option>
                                            ))
                                        }
                                    </Select>
                                </FormItem>
                                : null
                        )}
                    </Form.Item>
                    <FormItem label="Postcode" rules={[{ required: true }]} name={[keyPath, ":address", "postcode"]} span={8}>
                        <InputNumber maxLength={6} style={{ width: '100%' }} />
                    </FormItem>
                </Row>
            </>
        )
    }

    return (
        <Form
            form={form}
            layout="vertical"
            size="large"
            className="leasing-step-form"
            colon={false}
            initialValues={props.initialValues}
            onFinish={(values) => {
                console.log('Values', values)
                if (_.get(values, ":currentAddress.:address.state", undefined) === undefined) {
                    values[":currentAddress"][":address"]["state"] = "na"
                }

                // previous address is checked
                if (checked) {
                    values[":previousAddress"][":address"]["state"] = "na"
                }

                if (props?.onNext) {
                    props.onNext(props.formName, values)
                } else {
                    props.onSubmit(values)
                }
            }}
        >
            <Typography.Title level={4}>Current Address</Typography.Title>
            <Divider />
            {renderAddress()}

            <Typography.Title level={5}>Landlord/Agent details of this property</Typography.Title>
            <Row gutter={24}>
                <FormItem label="Name of landlord" name={[":currentAddress", "landlordName"]} span={24}>
                    <Input />
                </FormItem>
                <FormItem label="Phone" name={[":currentAddress", "phone"]} span={6}>
                    <InputNumber addonBefore="+61" formatter={(v) => renderMobile(v)} minLength={9} maxLength={9} />
                </FormItem>
                <FormItem label="Weekly rent paid" rules={[{ required: true }]} name={[":currentAddress", "weeklyRentPaid"]} span={6}>
                    <InputNumber formatter={renderNumber} addonBefore="$" />
                </FormItem>
                <FormItem label="How long have you lived at your current address?" rules={[{ required: true }]} name={[":currentAddress", "livingTime", "years"]} span={6}>
                    <InputNumber addonAfter="Years" min={0} />
                </FormItem>
                <FormItem label="Months" rules={[{ required: true }]} name={[":currentAddress", "livingTime", "months"]} span={6}>
                    <InputNumber addonAfter="Months" min={0} max={11} />
                </FormItem>
            </Row>

            <Form.Item label="Why are you leaving this address?" rules={[{ required: true }]} name={[":currentAddress", "reasonForLeaving"]}>
                <Input.TextArea />
            </Form.Item>

            <Checkbox defaultChecked={checked} onChange={() => setChecked(s => !s)}>Add Previous Address</Checkbox>
            {
                checked &&
                <>
                    <Typography.Title level={4} style={{ marginTop: 24 }}>Previous Address</Typography.Title>
                    <Divider />
                    {renderAddress(true)}

                    <Typography.Title level={5}>Landlord/Agent details of this property</Typography.Title>
                    <Row gutter={24}>
                        <FormItem label="Name of landlord" name={[":previousAddress", "landlordName"]} span={24}>
                            <Input />
                        </FormItem>
                        <FormItem label="Phone" name={[":previousAddress", "phone"]} span={6}>
                            <InputNumber addonBefore="+61" formatter={(v) => renderMobile(v)} minLength={9} maxLength={9} />
                        </FormItem>
                        <FormItem label="Weekly rent paid" rules={[{ required: true }]} name={[":previousAddress", "weeklyRentPaid"]} span={6}>
                            <InputNumber formatter={renderNumber} addonBefore="$" />
                        </FormItem>
                        <FormItem label="How long have you leaved at your current address?" rules={[{ required: true }]} name={[":previousAddress", "livingTime", "years"]} span={6}>
                            <InputNumber min={0} addonAfter="Years" />
                        </FormItem>
                        <FormItem label="Months" rules={[{ required: true }]} name={[":previousAddress", "livingTime", "months"]} span={6}>
                            <InputNumber addonAfter="Months" min={0} max={11} />
                        </FormItem>
                    </Row>

                    <Form.Item name={[":previousAddress", "bondFullyRefunded"]} label="Was bond refunded in full?">
                        <Radio.Group>
                            <Radio value={1}>Yes</Radio>
                            <Radio value={0}>No</Radio>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item label="Why not?" name={[":previousAddress", "reasonNotFullyRefunded"]}>
                        <Input.TextArea />
                    </Form.Item>
                </>
            }

            <Actions
                onNext={props?.onNext}
                onPrevious={props?.onPrevious}
                onSubmit={props?.onSubmit}
                loading={props?.saving}
            />
        </Form>
    )
}

export default FormAddress;
