import React, { Fragment, useState } from 'react'
import {
    Row,
    Col,
    DatePicker,
    Form,
    Input,
    InputNumber,
} from 'antd'
import FormItem from '../../components/FormItem'
import { Actions } from '../../pages/leasing-application/[id]'
import { renderNumber } from '../../utils/formatters'

const FormLeasing = (props) => {
    const [form] = Form.useForm()

    return (
        <Fragment>
            {props?.initialValues?.rent&&<Form
                form={form}
                layout="vertical"
                size="large"
                className="leasing-step-form"
                colon={false}
                initialValues={props.initialValues}
                onFinish={(values) => {
                    if (props?.onNext) {
                        props.onNext(props.formName, values)
                    } else {
                        props.onSubmit(values)
                    }
                }}
            >
                <Row gutter={24}>
                    <FormItem label="Weekly rent" rules={[{ required: true }]} name="rent">
                        <InputNumber min={1} formatter={renderNumber} addonBefore="$" addonAfter="per week" placeholder="500" onChange={(v) => form.setFieldsValue({ bond: parseInt(v) * 4 })} />
                    </FormItem>
                    <FormItem label="Bond" rules={[{ required: true }]} name="bond">
                        <InputNumber formatter={renderNumber} addonBefore="$" placeholder="2,000" style={{ width: '100%' }} />
                    </FormItem>
                    <FormItem label="Lease commence date" rules={[{ required: true }]} name="leaseCommenceDate">
                        <DatePicker style={{ width: '100%' }} format="DD/MM/YYYY" disabledDate={(current) => current.isBefore(new Date(), 'day')} />
                    </FormItem>
                    <FormItem label="Lease term" rules={[{ required: true }]} span={6} name={["leaseTerm", "years"]}>
                        <InputNumber min={0} max={50} addonAfter="Years" />
                    </FormItem>
                    <FormItem label="month" rules={[{ required: true }]} span={6} name={["leaseTerm", "months"]}>
                        <InputNumber min={0} max={11} addonAfter="Months" />
                    </FormItem>
                    <FormItem label="Number of Adults" rules={[{ required: true }]} span={6} name="numAdult">
                        <InputNumber min={1} style={{ width: '100%' }} />
                    </FormItem>
                    <FormItem label="Number of children" rules={[{ required: true }]} name="numChildren" span={6}>
                        <InputNumber min={0} max={3} style={{ width: '100%' }} />
                    </FormItem>
                </Row>
                <Row gutter={24}>
                    <Form.Item
                        noStyle
                        shouldUpdate={(prevValues, currentValues) => prevValues.numChildren !== currentValues.numChildren}
                    >
                        {({ getFieldValue }) =>
                            getFieldValue('numChildren') > 0 ? (
                                (new Array(getFieldValue('numChildren'))).fill(0).map((item, idx) => (
                                    <Col key={idx} span={6}>
                                        <Form.Item label={`Age of child ${idx + 1}`} name={`childAge-${idx}`} rules={[{ required: true }]}>
                                            <InputNumber min={1} max={18} style={{ width: '100%' }} />
                                        </Form.Item>
                                    </Col>
                                ))
                            ) : null
                        }
                    </Form.Item>
                </Row>

                <Actions
                    onNext={props?.onNext}
                    onPrevious={props?.onPrevious}
                    onSubmit={props?.onSubmit}
                    loading={props?.saving}
                />
            </Form>}
        </Fragment>
    )
}

export default FormLeasing;
