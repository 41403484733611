import React, { useState } from "react";
import { message, Upload } from 'antd'
import { PlusOutlined, LoadingOutlined } from '@ant-design/icons'

import './SinglePhotoUpload.less'

function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
}

function beforeUpload(file) {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
        message.error('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
        message.error('Image must smaller than 2MB!');
    }
    return isJpgOrPng && isLt2M;
}


const SinglePhotoUpload = ({
    defaultPhoto = false,
    ossData,
    afterUpload,
    onRemove,
}) => {
    const [loading, setLoading] = useState(false)
    const [imageUrl, setImageUrl] = useState(defaultPhoto)

    const uploadButton = (
        <div>
            {loading ? <LoadingOutlined /> : <PlusOutlined />}
            <div style={{ marginTop: 8 }}>Upload</div>
        </div>
    );

    const transformFile = (file) => {
        const suffix = file.name.slice(file.name.lastIndexOf("."));
        const filename = Date.now() + suffix;
        file.url = ossData.dir + "/" + filename;

        return file;
    };

    const getExtraData = (file) => {
        return {
            key: file.url,
            OSSAccessKeyId: ossData.accessId,
            policy: ossData.policy,
            Signature: ossData.signature,
        };
    };

    const handleChange = info => {
        if (info.file.status === 'uploading') {
            setLoading(true)
            return;
        }
        if (info.file.status === 'done') {
            // Get this url from response in real world.
            getBase64(info.file.originFileObj, imageUrl => {
                setLoading(false)
                setImageUrl(imageUrl)
            });

            if (afterUpload) { afterUpload(info.file) }
        }
    };

    return (
        <Upload
            className="avatar-uploader"
            name="file"
            listType="picture-card"
            action={ossData.host}
            multiple={false}
            showUploadList={false}
            transformFile={transformFile}
            data={getExtraData}
            beforeUpload={beforeUpload}
            onChange={handleChange}
            onRemove={(file) => {
                if (onRemove) {
                    onRemove(file)
                }
            }}
        >
            {imageUrl ? <img src={imageUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
        </Upload>
    )
}

export default SinglePhotoUpload