import React, { useRef, useState } from 'react'
import {
    Row,
    Col,
    Card,
    Input,
    Form,
    InputNumber,
    Typography,
    message,
    Divider,
    Button
} from 'antd'
import { compose } from 'recompose'
import { inject } from 'mobx-react'
import { InboxOutlined } from '@ant-design/icons'
import ReactSignatureCanvas from 'react-signature-canvas'
import moment from 'moment'
import split from "lodash/split"

import FormItem from '../../components/FormItem'
import { Actions } from '../../pages/leasing-application/[id]'
import SinglePhotoUpload from '../../components/SinglePhotoUpload'
import MultipleUpload from '../../components/MultipleUpload'

const styles = {
    signContainer: {
        width: 400,
        height: 200,
        border: '1px solid lightgrey'
    },
    clearBtn: {
        marginTop: 12
    }
}

const signPngPrefix = "data:image/png;base64,"

const FormOther = (props) => {
    const { ossData, appStore, leasingId, } = props

    const [form] = Form.useForm()
    const signatureRef = useRef()
    const ref = useRef({ hiddenValues: {} })
    
    const [sign, setSign] = useState(props.initialValues?.["signature"])

    const afterPetPhotoUpload = (idx, file) => {
        const ossUrl = ossData.host + '/' + file.url
        form.setFieldsValue({ [`photo-${idx}`]: ossUrl })
        ref.current.hiddenValues = { ...ref.current.hiddenValues, [`photo-${idx}`]: ossUrl }
    }

    const supportingUploadChange = async ({ file, fileList }) => {
        fileList.forEach(f => { f.ossUrl = ossData.host + '/' + f.url })

        ref.current.hiddenValues = { ...ref.current.hiddenValues, [":documents"]: fileList }
        // console.log('TEST', ref.current.hiddenValues)
        try {
            if (file.status === 'done') {
                const res = await appStore.clientApi.addLeasingApplicationDocument_(leasingId, {
                    url: file.ossUrl,
                    fileName: file.ossUrl?.substring(file.ossUrl?.lastIndexOf('/') + 1)
                })
                if (res?.id) {
                    file.id = res.id
                }
            } else if (file.status === 'removed' && file.id) {
                await appStore.clientApi.deleteLeasingApplicationDocument_(leasingId, file.id)
            }
        } catch (err) {
            message.error(err)
        }
    }

    const onClearSign = () => {
        if (typeof signatureRef?.current?.clear === 'function') {
            const res = signatureRef?.current?.clear();
        }
        setSign(null)
    }

    return (
        <Form
            form={form}
            layout="vertical"
            size="large"
            className="leasing-step-form"
            colon={false}
            initialValues={props.initialValues}
            onFinish={(values) => {
                if (props.initialValues) {
                    values = { ...props.initialValues, ...values }
                }

                const hiddenValues = ref.current.hiddenValues
                if (props?.onNext) {
                    const base64str = signatureRef?.current?.getTrimmedCanvas().toDataURL()
                    const trimmedBase64str = split(base64str,',')[1]

                    if (trimmedBase64str) {
                        hiddenValues["signature"] = trimmedBase64str
                        hiddenValues["signedDate"] = moment().format('YYYY-MM-DD')
                    }

                    props.onNext(props.formName, { ...values, ...hiddenValues })
                } else {
                    props.onSubmit({ ...values, ...hiddenValues })
                }
            }}
        >
            <Typography.Title level={4}>Car</Typography.Title>
            <Row gutter={24}>
                <FormItem label="Number of cars" rules={[{ required: true }]} name="numCars">
                    <InputNumber min={0} max={3} />
                </FormItem>
            </Row>
            <Row gutter={24}>
                <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, currentValues) => prevValues.numCars !== currentValues.numCars}
                >
                    {({ getFieldValue }) =>
                        getFieldValue('numCars') > 0 ? (
                            (new Array(getFieldValue('numCars'))).fill(0).map((item, idx) => (
                                <Col span={6} key={idx}>
                                    <Form.Item label={`${idx + 1}. Car registration`} name={`car-${idx}`} rules={[{ required: true }]}>
                                        <Input style={{ width: '100%' }} />
                                    </Form.Item>
                                </Col>
                            ))
                        ) : null
                    }
                </Form.Item>
            </Row>
            <Typography.Title level={4}>Pets</Typography.Title>
            <Row gutter={24}>
                <FormItem label="Number of pets" rules={[{ required: true }]} name="numPet">
                    <InputNumber min={0} max={3} />
                </FormItem>
            </Row>
            <Row gutter={24}>
                <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, currentValues) => prevValues.numPet !== currentValues.numPet}
                >
                    {({ getFieldValue }) =>
                        getFieldValue('numPet') > 0 ? (
                            (new Array(getFieldValue('numPet'))).fill(0).map((item, idx) => (
                                <Col key={idx} span={12} style={{ marginBottom: 24 }}>
                                    <Card title={`Pet ${idx + 1}`}>
                                        <Form.Item label="Breed/type" name={`breedType-${idx}`} rules={[{ required: true }]}>
                                            <Input />
                                        </Form.Item>
                                        <Form.Item label="Council registration / number" name={`councilRegistration-${idx}`} rules={[{ required: true }]}>
                                            <Input />
                                        </Form.Item>
                                        <Form.Item label="Weight" name={`weight-${idx}`} rules={[{ required: true }]}>
                                            <InputNumber addonAfter="kg" min={1} />
                                        </Form.Item>
                                        <SinglePhotoUpload defaultPhoto={props.initialValues?.[`photo-${idx}`]} ossData={ossData} afterUpload={(file) => afterPetPhotoUpload(idx, file)} />
                                    </Card>
                                </Col>
                            ))
                        ) : null
                    }
                </Form.Item>
            </Row>

            <Typography.Title level={4}>Supporting Documents</Typography.Title>
            <MultipleUpload
                ossData={ossData}
                onChange={supportingUploadChange}
                defaultList={props.initialValues?.[":documents"]}
            >
                <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                </p>
                <p className="ant-upload-text">Click or drag file to this area to upload</p>
                <p className="ant-upload-hint">
                    Support for a single or bulk upload.
                </p>
            </MultipleUpload>

            <Divider />
            <Typography.Title level={4}>Signature</Typography.Title>

            {
                sign
                    ?
                    <div>
                        <img alt="signature" src={signPngPrefix + sign} />
                    </div>
                    :
                    <div style={styles.signContainer}>
                        <ReactSignatureCanvas
                            ref={signatureRef}
                            canvasProps={styles.signContainer}
                        />
                    </div>

            }
            <Button style={styles.clearBtn} danger onClick={onClearSign}>Clear</Button>

            <Actions
                onNext={props?.onNext}
                onPrevious={props?.onPrevious}
                onSubmit={props?.onSubmit}
                loading={props?.saving}
            />
        </Form>
    )
}

export default compose(inject('appStore'))(FormOther);
